import { useId, useState } from 'react'
import { Link, Outlet, useLocation, useNavigate } from '@tanstack/react-router'
import {
  AppBar as MuiAppBar,
  Box,
  CssBaseline,
  Drawer as MuiDrawer,
  styled,
  Toolbar,
  IconButton,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Button
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'
import CollectionsIcon from '@mui/icons-material/Collections'
import AirplaneTicketIcon from '@mui/icons-material/AirplaneTicket'
import ReviewsIcon from '@mui/icons-material/Reviews'
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts'
import LogoutIcon from '@mui/icons-material/Logout'
import { getAuth, signOut } from 'firebase/auth'

const drawerWidth = 240

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen
  }),
  overflowX: 'hidden',
  backgroundColor: theme.palette.primary.main,
  color: 'white'
})

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`
  },
  backgroundColor: theme.palette.primary.main,
  color: 'white'
})

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar
}))

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme)
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme)
  })
}))

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  })
}))

const ListDrawerItem = ({ items, open }) => {
  const navigate = useNavigate()
  const currentPath = useLocation().pathname
  return (
    <List>
      {items.map((item) => (
        <ListItem
          key={item.key}
          disablePadding
          sx={{ display: 'block' }}
          title={item.text}>
          <ListItemButton
            sx={{
              minHeight: 48,
              justifyContent: open ? 'initial' : 'center',
              px: 2.5
            }}
            onClick={
              item?.path ? () => navigate({ to: item.path }) : item?.onClick
            }
            disabled={currentPath === item.path}>
            <ListItemIcon
              sx={{
                color: item?.color || 'inherit',
                minWidth: 0,
                mr: open ? 3 : 'auto',
                justifyContent: 'center'
              }}>
              <item.Icon />
            </ListItemIcon>
            <ListItemText
              primary={item.text}
              sx={{ opacity: open ? 1 : 0, color: item.color || 'inherit' }}
            />
          </ListItemButton>
        </ListItem>
      ))}
    </List>
  )
}
const SideBar = () => {
  const [open, setOpen] = useState(false)

  const handleDrawerOpen = () => setOpen(true)
  const handleDrawerClose = () => setOpen(false)

  const upperItems = [
    {
      key: useId(),
      text: 'Package',
      Icon: AirplaneTicketIcon,
      path: '/cms/package'
    },
    {
      key: useId(),
      text: 'Gallery',
      Icon: CollectionsIcon,
      path: '/cms/gallery'
    },
    {
      key: useId(),
      text: 'Testimony',
      Icon: ReviewsIcon,
      path: '/cms/testimony'
    }
  ]

  const bottomItems = [
    {
      key: useId(),
      text: 'Account Settings',
      Icon: ManageAccountsIcon,
      path: '/cms/account-setting'
    },
    {
      key: useId(),
      text: 'Logout',
      color: '#b8b8b8',
      Icon: LogoutIcon,
      onClick: () => {
        const auth = getAuth()
        signOut(auth)
      }
    }
  ]

  return (
    <Box display='flex'>
      <CssBaseline />
      <AppBar position='fixed' open={open}>
        <Toolbar>
          <IconButton
            color='inherit'
            aria-label='open drawer'
            onClick={handleDrawerOpen}
            edge='start'
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' })
            }}>
            <MenuIcon />
          </IconButton>
          <Typography variant='h6' noWrap component='div' flexGrow={1}>
            Dima Tour & Travel
          </Typography>

          <Link to='/'>
            <Button variant='text' sx={{ color: 'white' }} size='large'>
              Go To Website
            </Button>
          </Link>
        </Toolbar>
      </AppBar>

      <Drawer variant='permanent' open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose} color='inherit'>
            <ChevronLeftIcon />
          </IconButton>
        </DrawerHeader>
        <Divider />
        <ListDrawerItem items={upperItems} open={open} />
        <Divider sx={{ flexGrow: 1 }} />
        <ListDrawerItem items={bottomItems} open={open} />
      </Drawer>

      <Box component='main' sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  )
}

export default SideBar

import { useMemo, useState } from 'react'
import {
  Box,
  Container,
  Divider,
  Grid,
  IconButton,
  Popover,
  Rating,
  Tooltip,
  Typography,
  useTheme
} from '@mui/material'
import { useLocation } from '@tanstack/react-router'
import FlightIcon from '@mui/icons-material/Flight'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import DirectionsBusIcon from '@mui/icons-material/DirectionsBus'
import LinkIcon from '@mui/icons-material/InsertLink'
import JumbotronDetailPackage from './JumbotronDetailPackage'
import dayjs from 'dayjs'
import { CompanyCard, PackageWidgetSidebar } from '../../components'

const GridIconHeader = ({ theme, Icon, iconTitle, iconCaption }) => {
  return (
    <Grid item xs={12} sm={6} md={3} lg={6}>
      <Box display='flex' alignItems='center'>
        <Box
          sx={{
            backgroundColor: theme.palette.primary.main,
            borderRadius: '50%',
            height: 40,
            width: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: 2
          }}>
          <Icon sx={{ color: 'white' }} />
        </Box>
        <Box>
          <Typography variant='body2'>{iconTitle}</Typography>
          <Typography variant='body2' fontWeight='fontWeightBold'>
            {iconCaption}
          </Typography>
        </Box>
      </Box>
    </Grid>
  )
}

const DetailPackagePage = () => {
  const theme = useTheme()
  const { state: stateData } = useLocation()

  const [anchorEl, setAnchorEl] = useState(null)
  const [open, setOpen] = useState(false)

  const handleCopy = (event) => {
    setOpen(true)
    navigator.clipboard.writeText(window.location.href)
    setAnchorEl(event.currentTarget)
    setTimeout(handleClose, 1000)
  }

  const handleClose = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  const packageData = useMemo(() => ({ ...stateData?.data }), [stateData])

  return (
    <>
      <JumbotronDetailPackage
        img={packageData.fileUrl}
        title={packageData.name}
      />

      {/* Detail Header */}
      <Box sx={{ backgroundColor: theme.palette.grey[100], py: 5 }}>
        <Container>
          <Grid container direction={{ xs: 'column-reverse', lg: 'row' }}>
            <Grid item xs={12} lg={5}>
              <Grid container spacing={2}>
                <GridIconHeader
                  theme={theme}
                  Icon={FlightIcon}
                  iconTitle='Maskapai'
                  iconCaption={packageData.airline}
                />
                <GridIconHeader
                  theme={theme}
                  Icon={AccessTimeIcon}
                  iconTitle='Durasi'
                  iconCaption={packageData.duration + ' Hari'}
                />
                <GridIconHeader
                  theme={theme}
                  Icon={DirectionsBusIcon}
                  iconTitle={packageData.departure}
                  iconCaption={dayjs
                    .unix(packageData.scheduleTime)
                    .format('DD MMM YYYY')}
                />
              </Grid>
            </Grid>

            <Grid item xs={12} lg={7} alignContent='center'>
              <Box
                mb={{ xs: 5, lg: 0 }}
                textAlign={{ xs: 'left', lg: 'right' }}>
                <Typography variant='h6'>Mulai Dari</Typography>
                <Typography
                  color={theme.palette.secondary.main}
                  fontWeight='fontWeightBold'
                  variant='h5'>
                  Rp. {packageData.price?.toLocaleString()}
                </Typography>
                <Typography variant='body2'>Per Pax Jamaah</Typography>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>

      {/* Package */}
      <Container sx={{ marginY: theme.spacing(4) }}>
        <Grid container spacing={2}>
          {/* Package Content */}
          <Grid item xs={12} md={8}>
            <Box
              component='img'
              src={packageData.fileUrl}
              height={'auto'}
              width={'100%'}
            />
            <Box display='flex' gap={2} my={3} alignItems='center'>
              <Typography>Hotel Rate</Typography>
              <Rating
                size='medium'
                name='read-only'
                value={Number(packageData.hotelStar)}
                readOnly
                sx={{ flexGrow: 1 }}
              />
              <Typography>Share: </Typography>
              <Tooltip title='Copy Link'>
                <IconButton
                  aria-describedby='icon-copy-link'
                  onClick={handleCopy}>
                  <LinkIcon />
                </IconButton>
              </Tooltip>
              <Popover
                id='icon-copy-link'
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}>
                <Typography variant='caption' p={1}>
                  Copied!
                </Typography>
              </Popover>
            </Box>
            <Divider />
            <div
              dangerouslySetInnerHTML={{ __html: packageData.description }}
            />
          </Grid>

          {/* Sidebar */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                position: { xs: 'inherit', md: 'sticky' },
                top: 80,
                display: 'flex',
                gap: 2,
                flexDirection: 'column'
              }}>
              <CompanyCard />
              <PackageWidgetSidebar />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  )
}

export default DetailPackagePage

import { Store } from '@tanstack/react-store'

/**
 * @typedef PackageListState
 * @prop {Array} docs
 * @prop {Object} startAfterDoc
 * @prop {Object} endAfterDoc
 * @prop {number} count
 * @prop {number} page
 * @prop {number} perPage
 * @prop {boolean} isLoading
 * @prop {boolean} isActive
 */

/**
 * @type {PackageListState}
 */
export const packageListState = {
  docs: [],
  startAfterDoc: null,
  endAfterDoc: null,
  count: 10,
  page: 0,
  perPage: 10,
  isLoading: false,
  isActive: true
}

// ================================== Active Package On CMS

export const storePackageList = new Store(packageListState)

/**
 *
 * @param {PackageListState} newState
 */
export const setStateStorePackageList = (newState) => {
  storePackageList.setState((oldState) => ({ ...oldState, ...newState }))
}

// ================================== Inactive Package On CMS

export const storePackageInactiveList = new Store({
  ...packageListState,
  isActive: false
})

/**
 *
 * @param {PackageListState} newState
 */
export const setStateStorePackageInactiveList = (newState) => {
  storePackageInactiveList.setState((oldState) => ({
    ...oldState,
    ...newState
  }))
}

// ================================== List Package On Home

export const storePackageHome = new Store({ ...packageListState, perPage: 3 })

/**
 *
 * @param {PackageListState} newState
 */
export const setStateStorePackageHome = (newState) => {
  storePackageHome.setState((oldState) => ({ ...oldState, ...newState }))
}

// ================================== List Package On Package Page

export const storePackagePage = new Store(packageListState)

/**
 *
 * @param {PackageListState} newState
 */
export const setStateStorePackagePage = (newState) => {
  storePackagePage.setState((oldState) => {
    let docs = oldState.docs
    if (newState?.docs?.length) {
      docs = [...oldState.docs, ...newState.docs].reduce((pv, cv) => {
        const found = pv.find((v) => v.id === cv.id)
        if (!found) {
          pv.push(cv)
        }
        return pv
      }, [])
    }

    return { ...oldState, ...newState, docs }
  })
}

// ================================== List Package On Widget Sidebar

export const storePackageSidebar = new Store({
  ...packageListState,
  perPage: 4
})

/**
 *
 * @param {PackageListState} newState
 */
export const setStateStorePackageSidebar = (newState) => {
  storePackageSidebar.setState((oldState) => ({ ...oldState, ...newState }))
}

// ================================== Image Package On CMS

/**
 * @typedef ImagePackage
 * @prop {string} img
 * @prop {string} title
 */

/**
 * @typedef ImagePackageState
 * @prop {ImagePackage[]} data
 * @prop {boolean} isLoading
 * @prop {number} totalData
 */

/**
 * @type {ImagePackageState}
 */
const imagePackageState = {
  data: [],
  isLoading: false,
  totalData: 10
}
export const storeImagePackageList = new Store(imagePackageState)

/**
 *
 * @param {ImagePackageState} newState
 */
export const setStateImagePackageList = (newState) => {
  storeImagePackageList.setState((oldState) => ({ ...oldState, ...newState }))
}

// ==================================

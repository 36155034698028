import { createRoute } from '@tanstack/react-router'
import { PackagePage, DetailPackagePage } from '../pages'
import { publicRoute } from './Root'

export const packageRoute = createRoute({
  getParentRoute: () => publicRoute,
  path: '/package',
  component: PackagePage
})

export const packageDetailRoute = createRoute({
  getParentRoute: () => publicRoute,
  path: '/package/$packageId',
  component: DetailPackagePage
})

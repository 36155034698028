import dayjs from 'dayjs'
import {
  addDoc,
  getDocs,
  collection,
  getFirestore,
  query,
  doc,
  getDoc,
  setDoc,
  getCountFromServer
} from 'firebase/firestore'

function getDb() {
  return getFirestore()
}

/**
 * @param {string} collName collection name
 * @param {any} data
 */
async function insertDoc(collName, data) {
  const now = dayjs().unix()
  data = { ...data, createdAt: now, updatedAt: now }
  const docRef = await addDoc(collection(getDb(), collName), data)

  return { ...data, id: docRef.id }
}

export async function updateDoc(collName, id, data) {
  const docRef = doc(getDb(), collName, id)
  const now = dayjs().unix()
  await setDoc(docRef, { ...data, updatedAt: now }, { merge: true })
}

/**
 *
 * @param {string} collName
 * @param {any[]} queries
 * @returns {Promise<{docs,startAfterDoc,endAfterDoc}>} A promise that resolves to an array of documents.
 */
async function getAllDocs(collName, ...queries) {
  const col = collection(getDb(), collName)
  const queryDocs = query(col, ...queries)
  const querySnapshot = await getDocs(queryDocs)
  const startAfterDoc = querySnapshot.docs[querySnapshot.docs.length - 1]
  const endAfterDoc = querySnapshot.docs[0]
  const docs = querySnapshot.docs.map((v) => ({ id: v.id, ...v.data() }))

  return { docs, startAfterDoc, endAfterDoc }
}

/**
 *
 * @param {string} collName
 * @param {any[]} queries
 * @returns {Promise<number>} A promise that resolves to an array of documents.
 */
async function countDocs(collName, ...queries) {
  const col = collection(getDb(), collName)
  const q = query(col, ...queries)
  const qCount = await getCountFromServer(q)
  return qCount.data().count
}

/**
 * @param {string} collName
 * @param {string} id
 */
async function getOneDocById(collName, id) {
  const docRef = doc(getDb(), collName, id)
  const docSnap = await getDoc(docRef)

  if (docSnap.exists()) {
    return { ...docSnap.data(), id }
  }

  return null
}

export { insertDoc, getAllDocs, getOneDocById, countDocs }

export const getFileNameFromUrl = (url) => {
  const fileName = url.split('/').pop().split('?')[0]
  return fileName
}

/**
 * @typedef ImageFormated
 * @property {string} img
 * @property {string} title
 * @property {string} reference
 *
 * @param {any} v
 * @returns {ImageFormated}
 */
export const formatImageResultUrl = (v) => ({
  img: v,
  title: `${getFileNameFromUrl(decodeURIComponent(v))}`,
  reference: `${decodeURIComponent(getFileNameFromUrl(v))}`
})

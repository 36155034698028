import { useContext, useState } from 'react'
import { Box, Button, Paper, TextField, Typography } from '@mui/material'
import { useForm } from '@tanstack/react-form'
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth'
import { Context } from '../../../context/AuthContext'
import { Navigate, useNavigate } from '@tanstack/react-router'

const LoginCmsPage = () => {
  const { user } = useContext(Context)
  const [loading, setLoading] = useState(false)
  const [loginMessage, setLoginMessage] = useState('')
  const navigate = useNavigate()

  const { Field, handleSubmit } = useForm({
    defaultValues: { email: '', password: '' },
    onSubmit: async ({ value }) => {
      try {
        setLoginMessage('')
        const auth = getAuth()
        setLoading(true)
        await signInWithEmailAndPassword(auth, value.email, value.password)
        setLoading(false)
        navigate('/cms')
      } catch (error) {
        setLoginMessage(error?.code)
        setLoading(false)
      }
    }
  })

  const handleLogin = (e) => {
    e.preventDefault()
    handleSubmit()
  }

  if (user) {
    return <Navigate to='/cms' replace />
  }

  return (
    <Box
      minHeight='100vh'
      display='flex'
      flexDirection='column'
      justifyContent='center'>
      <Paper
        variant='outlined'
        sx={{ margin: 'auto', width: { xs: '90%', sm: '75%', md: '50%' } }}>
        <Box
          component='form'
          alignItems='center'
          display='flex'
          flexDirection='column'
          gap={4}
          p={5}
          onSubmit={handleLogin}>
          <Typography variant='h4' fontWeight='fontWeightBold'>
            Log In
          </Typography>
          <Field
            name='email'
            children={({ state, handleChange, handleBlur }) => (
              <TextField
                defaultValue={state.value}
                onChange={(e) => handleChange(e.target.value)}
                onBlur={handleBlur}
                label='Email'
                placeholder='your@mail.com'
                type='email'
                fullWidth
                required
              />
            )}
          />
          <Field
            name='password'
            children={({ state, handleChange, handleBlur }) => (
              <TextField
                defaultValue={state.value}
                onChange={(e) => handleChange(e.target.value)}
                onBlur={handleBlur}
                label='Password'
                placeholder='your magic word'
                type='password'
                fullWidth
                required
              />
            )}
          />
          <Typography variant='body2' color={(t) => t.palette.error.main}>
            {loginMessage}
          </Typography>
          <Button
            variant='contained'
            type='submit'
            disabled={loading}
            fullWidth>
            LOGIN
          </Button>
          <Typography variant='caption' color={(t) => t.palette.grey[500]}>
            Please contact the administrator if you forget your password
          </Typography>
        </Box>
      </Paper>
    </Box>
  )
}

export default LoginCmsPage

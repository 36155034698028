import { useId } from 'react'
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Container,
  Grid,
  Typography,
  useTheme
} from '@mui/material'
import LegalPaperImg from '../../assets/002-legal-paper.png'
import TrustImg from '../../assets/003-trust.png'
import CooperationImg from '../../assets/005-cooperation.png'
import IndonesiaImg from '../../assets/007-indonesia.png'
import GuideImg from '../../assets/008-guide.png'
import HotelImg from '../../assets/009-hotel.png'
import UnitedArabEmiratesImg from '../../assets/001-united-arab-emirates.png'

const WhyUs = () => {
  const theme = useTheme()

  const contents = [
    {
      imgUrl: LegalPaperImg,
      title: 'Terpercaya',
      body: 'Resmi terdaftar di Kementrian Agama Republik Indonesia',
      key: useId()
    },
    {
      imgUrl: TrustImg,
      title: 'Sesuai Syariat Islam',
      body: 'Semua kegiatan yang dilakukan mulai dari Manasik hingga Ibadah Umrah atau Haji InsyaAllah sesuai Al-Quran dan As-sunnah',
      key: useId()
    },
    {
      imgUrl: CooperationImg,
      title: 'Kajian Agama Selama di Saudi',
      body: 'Aktifitas Jemaah selama di Saudi akan dipenuhi dengan kajian Majelis ilmu',
      key: useId()
    },
    {
      imgUrl: IndonesiaImg,
      title:
        'Muthawif dan Tim Lapangan di Saudi adalah Orang Indonesia yang berizin tinggal resmi di Saudi',
      body: 'Pembimbing Muthawif yang keilmuannya InsyaAllah sesuai dengan Al-Quran dan As-sunnah',
      key: useId()
    },
    {
      imgUrl: GuideImg,
      title: 'Pembimbing Umrah atau Haji Profesional & Berpengalaman',
      body: 'InsyaAllah akan dibimbing dengan Pembimbing sesuai Sunnah yang amanah, berkompeten dan berpengalaman di bidangnya selama bertahun-tahun',
      key: useId()
    },
    {
      imgUrl: HotelImg,
      title: 'Fasilitas Nyaman dan Baik',
      body: 'Menggunakan Fasilitas yang terbaik | untuk para Jemaah kami : penerbangan berstandar IATA, Transportasi BUS Full AC & Nyaman, Hotel berbintang yang dekat dengan Masjidil Haram & Masjid Nabawi',
      key: useId()
    },
    {
      imgUrl: UnitedArabEmiratesImg,
      title:
        'Team LA Lapangan di Saudi berizin resmi di Kementrian Haji & Umrah Saudi',
      body: 'Bekerjasama dengan Muasasah yang berizin Resmi di Kementrian (Haji & Umrah) di Saudi sebagai Penyedia Jasa LA (Land Arrangement)',
      key: useId()
    }
  ]

  return (
    <Container sx={{ marginY: theme.spacing(12) }}>
      <Box marginBottom={theme.spacing(6)}>
        <Typography fontWeight='fontWeightBold' variant='h4'>
          Mengapa Memilih Kami ?
        </Typography>
        <Typography variant='body1'>
          Membantu saudara muslim sebanyak-banyaknya untuk bisa berangkat Umrah
          & Haji dengan mudah dan nyaman.
        </Typography>
      </Box>

      <Grid container spacing={5}>
        {contents.map((c) => (
          <Grid item xs={12} md={4} key={c.key} textAlign='center'>
            <Card
              sx={{
                backgroundColor: theme.palette.grey[50],
                transition:
                  'background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                '&:hover': {
                  backgroundColor: 'primary.main',
                  transition:
                    'background-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                  color: 'white',
                  boxShadow: theme.shadows[20]
                },
                boxShadow: 'none',
                borderRadius: theme.shape.borderRadius,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                textAlign: 'center',
                p: theme.spacing(4),
                height: '100%'
              }}>
              <CardMedia
                component='img'
                sx={{ width: 52 }}
                image={c.imgUrl}
                title={c.title}
              />
              <CardContent>
                <Typography variant='subtitle1' fontWeight='fontWeightBold'>
                  {c.title}
                </Typography>
                <Typography>{c.body}</Typography>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  )
}

export default WhyUs

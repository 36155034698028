import {
  getAllPackages,
  insertOrUpdatePackage,
  getPackageById
} from '../../firebase/firestore/package'
import {
  PathStoragePackages,
  getAllFromBucket,
  uploadFromFile
} from '../../firebase/storage'
import { getFileNameFromUrl } from '../../libs/helper'
import {
  packageListState,
  setStateImagePackageList,
  setStateStorePackageList,
  setStateStorePackageInactiveList
} from '../store/package'

export const getPackagePagination = async (options, setState) => {
  setState({ isLoading: true })

  const result = await getAllPackages(options)
  const newState = {
    docs: result.docs,
    count: result.count,
    startAfterDoc: result.startAfterDoc,
    endAfterDoc: result.endAfterDoc,
    isLoading: false
  }
  setState(newState)
}

export const getImagePackage = async () => {
  setStateImagePackageList({ isLoading: true })
  const result = await getAllFromBucket(PathStoragePackages)
  setStateImagePackageList({
    isLoading: false,
    data: result.map((v) => ({
      img: v,
      title: `${getFileNameFromUrl(decodeURIComponent(v))}`
    })),
    totalData: result.length
  })
}

export const postPackage = async (value) => {
  let shouldRefetchImage = false
  if (value.fileUrl instanceof File) {
    const url = await uploadFromFile(value.fileUrl, PathStoragePackages)
    value.fileUrl = url
    shouldRefetchImage = true
  }
  await insertOrUpdatePackage(value)
  setStateStorePackageList(packageListState)
  setStateStorePackageInactiveList({ ...packageListState, isActive: false })
  if (shouldRefetchImage) {
    setStateImagePackageList({ data: [] })
  }
}

export const getOnePackageById = async (id) => getPackageById(id)

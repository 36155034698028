import {
  Box,
  Button,
  CircularProgress,
  Container,
  Grid,
  Typography,
  useTheme
} from '@mui/material'
import { useStore } from '@tanstack/react-store'
import { PackageCard } from '../../components'
import {
  storePackagePage,
  setStateStorePackagePage
} from '../../stores/store/package'
import { getPackagePagination } from '../../stores/query/package'
import { useCallback, useEffect } from 'react'

const PackagePage = () => {
  const theme = useTheme()

  const {
    docs,
    count,
    startAfterDoc: next,
    perPage,
    isLoading,
    isActive
  } = useStore(storePackagePage)
  const isFullLoad = count === docs?.length

  const fetchPackages = useCallback(getPackagePagination, [])

  const handleSeeMore = () => {
    fetchPackages(
      { perPage, next, direction: 'next', isActive },
      setStateStorePackagePage
    )
  }

  useEffect(() => {
    if (!docs.length && count !== 0) {
      fetchPackages({ perPage, isActive }, setStateStorePackagePage)
    }
  }, [fetchPackages, docs, perPage, isActive, count])

  return (
    <Container sx={{ marginY: theme.spacing(12) }}>
      <Typography variant='h3' fontWeight='fontWeightBold' textAlign='center'>
        Pilihan Paket{' '}
        <Typography
          variant='h3'
          fontWeight='fontWeightBold'
          component={'span'}
          color={theme.palette.primary.main}>
          Dima Tour
        </Typography>
      </Typography>

      <Grid container spacing={5}>
        {docs?.map((doc) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={`package_${doc.id}`}
            marginTop={theme.spacing(5)}>
            <PackageCard packageData={doc} />
          </Grid>
        ))}
      </Grid>

      <Box
        width='100%'
        mt={5}
        display='flex'
        flexDirection='column'
        alignItems='center'>
        {isLoading && <CircularProgress sx={{ m: 2 }} />}
        {!isLoading && (
          <Button
            variant='contained'
            onClick={handleSeeMore}
            disabled={isFullLoad}>
            {isFullLoad ? 'Sudah Menampilkan Semua Paket' : 'Lihat Lebih'}
          </Button>
        )}
      </Box>
    </Container>
  )
}

export default PackagePage

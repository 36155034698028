import { createRootRoute, createRoute } from '@tanstack/react-router'
import { HomePage, Layout, LayoutRoot } from '../pages'

export const rootRoute = createRootRoute({
  component: LayoutRoot
})

export const publicRoute = createRoute({
  getParentRoute: () => rootRoute,
  component: Layout,
  id: '_public'
})

export const homeRoute = createRoute({
  getParentRoute: () => publicRoute,
  path: '/',
  component: HomePage
})

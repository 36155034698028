import { Store } from '@tanstack/react-store'

/**
 * @typedef ImageGalleryInfiniteState
 * @prop {ImageGallery[]} data
 * @prop {boolean} isLoading
 * @prop {string | undefined} nextPageToken
 * @prop {number} totalData
 */

/**
 * @type {ImageGalleryInfiniteState}
 */
const imageGalleryInfiniteState = {
  data: [],
  isLoading: false,
  nextPageToken: undefined,
  totalData: 10,
}

// ============================= Gallery CMS

export const storeImageGalleryInfinite = new Store(imageGalleryInfiniteState)

/**
 * @param {ImageGalleryInfiniteState} newState
 */
export const setStateImageGalleryInfinite = (newState, reset = false) => {
  storeImageGalleryInfinite.setState((oldState) => {
    if (reset) return imageGalleryInfiniteState

    let data = oldState.data
    if (newState?.data?.length) {
      data = [...oldState.data, ...newState.data].reduce((pv, cv) => {
        const found = pv.find((v) => v.title === cv.title)
        if (!found) {
          pv.push(cv)
        }
        return pv
      }, [])
    }

    const result = { ...oldState, ...newState, data }

    return result
  })
}

// ============================= Gallery Home

const imageGalleryHomeState = {
  data: [],
  isLoading: false,
  nextPageToken: undefined,
  totalData: 10,
}

export const storeImageGalleryHome = new Store(imageGalleryHomeState)

export const setStateImageGalleryHome = (newState) => {
  storeImageGalleryHome.setState((old) => {
    return { ...old, ...newState }
  })
}

// ============================= Gallery Page

const imageGalleryPageState = {
  data: [],
  isLoading: false,
  nextPageToken: undefined,
  totalData: 10,
}

export const storeImageGalleryPage = new Store(imageGalleryPageState)

export const setStateImageGalleryPage = (newState, reset = false) => {
  storeImageGalleryPage.setState((oldState) => {
    if (reset) return imageGalleryPageState

    let data = oldState.data
    if (newState?.data?.length) {
      data = [...oldState.data, ...newState.data].reduce((pv, cv) => {
        const found = pv.find((v) => v.title === cv.title)
        if (!found) {
          pv.push(cv)
        }
        return pv
      }, [])
    }

    const result = { ...oldState, ...newState, data }

    return result
  })
}

import { createRoute } from '@tanstack/react-router'
import { cmsRootRoute } from '.'
import { PackageCmsPage, PackageListCmsPage } from '../../pages'

export const packageListCmsRoute = createRoute({
  getParentRoute: () => cmsRootRoute,
  path: '/cms/package',
  component: PackageListCmsPage
})

export const packageCmsRoute = createRoute({
  getParentRoute: () => cmsRootRoute,
  path: '/cms/package/$packageId',
  component: PackageCmsPage
})

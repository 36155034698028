import { useState } from 'react'
import { Tab, Tabs } from '@mui/material'
import { CmsContainer } from '../../../components'
import {
  storePackageList,
  setStateStorePackageList,
  storePackageInactiveList,
  setStateStorePackageInactiveList
} from '../../../stores/store/package'
import TablePackage from './TablePackage'

const PackageListCmsPage = () => {
  const [tabIndex, setTabIndex] = useState(0)

  const handleTabChange = (_, idx) => {
    setTabIndex(idx)
  }

  return (
    <CmsContainer title='Packages'>
      <Tabs value={tabIndex} onChange={handleTabChange} sx={{ mb: 2 }}>
        <Tab label='Active' id='active-tab' />
        <Tab label='Inactive' id='inactive-tab' />
      </Tabs>

      {/* Table Active Package */}
      {tabIndex === 0 && (
        <TablePackage
          setState={setStateStorePackageList}
          storeParams={storePackageList}
        />
      )}

      {/* Table Inactive Package */}
      {tabIndex === 1 && (
        <TablePackage
          setState={setStateStorePackageInactiveList}
          storeParams={storePackageInactiveList}
        />
      )}
    </CmsContainer>
  )
}

export default PackageListCmsPage

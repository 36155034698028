import { useState } from 'react'
import {
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions
} from '@mui/material'

function ConfirmationDialog({
  title,
  textConfirm = 'Yes',
  textCancel = 'No',
  description,
  response,
  colorConfirm = 'primary', //  'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning'
  ...props
}) {
  //local states
  const [open, setOpen] = useState(false)

  const showDialog = () => {
    setOpen(true)
  }

  const hideDialog = () => {
    setOpen(false)
  }

  const confirmRequest = async () => {
    await response()
    setTimeout(hideDialog, 1000)
  }

  return (
    <>
      {props.children(showDialog)}
      {open && (
        <Dialog
          open={open}
          onClose={hideDialog}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'>
          <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              {description}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={hideDialog} sx={{ color: 'gray' }}>
              {textCancel}
            </Button>
            <Button
              onClick={confirmRequest}
              color={colorConfirm}
              variant='contained'>
              {textConfirm}
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  )
}

export default ConfirmationDialog

import { useState } from 'react'
import { Box, Divider, Typography } from '@mui/material'
import { useStore } from '@tanstack/react-store'
import {
  CmsContainer,
  CmsImageList,
  InputFileUpload,
  LinearProgressWithLabel
} from '../../../components'
import {
  postTestimony,
  getTestimonyInfiniteScroll,
  deleteTestimony
} from '../../../stores/query/testimony'
import {
  setStateImageTestimonyInfinite,
  storeImageTestimonyInfinite
} from '../../../stores/store/testimony'

const TestimonyCmsPage = () => {
  const [error, setError] = useState(null)
  const [totalProgress, setTotalProgress] = useState(0)
  const { data, isLoading, nextPageToken, totalData } = useStore(
    storeImageTestimonyInfinite
  )

  const handleUpload = (files) => {
    const filesValue = Object.values(files)
    const totalFiles = filesValue.length
    const arrProgress = []
    filesValue?.map((file, idx) => {
      const setter = (progressValue) => {
        arrProgress[idx] = progressValue

        const sumUploadProgress = arrProgress.reduce((pv, cv) => pv + cv, 0)
        const progress = (sumUploadProgress / (totalFiles * 100)) * 100
        setTotalProgress(progress)

        if (progress === 100) {
          setTotalProgress(0)
        }

        return arrProgress
      }
      return postTestimony({
        file,
        setUploadProgress: setter,
        setErrorProgress: setError,
        refetch: () => {
          getTestimonyInfiniteScroll('', false, setStateImageTestimonyInfinite)
        }
      })
    })
  }

  return (
    <CmsContainer title='Testimony'>
      <Box
        display='flex'
        flexDirection='column'
        gap={2}
        textAlign='center'
        justifyContent='center'>
        <Box>
          <InputFileUpload
            title='Add Images'
            accept='image/*'
            multiple
            onChange={(e) => handleUpload(e.target.files)}
          />
        </Box>
        {!!totalProgress && <LinearProgressWithLabel value={totalProgress} />}
        <Typography variant='caption' color='red'>
          {error}
        </Typography>
      </Box>

      <Divider sx={{ my: 5 }} />

      <CmsImageList
        data={data}
        totalData={totalData}
        isLoading={isLoading}
        nextPageToken={nextPageToken}
        fetchImage={(token, isLoading) => {
          getTestimonyInfiniteScroll(
            token,
            isLoading,
            setStateImageTestimonyInfinite
          )
        }}
        deleteImage={deleteTestimony}
      />
    </CmsContainer>
  )
}

export default TestimonyCmsPage

import {
  deleteFileFromBucket,
  getFromBucketPaginate,
  PathStorageTestimony,
  uploadWithProgress
} from '../../firebase/storage'
import { formatImageResultUrl } from '../../libs/helper'
import {
  setStateImageTestimonyHome,
  setStateImageTestimonyInfinite
} from '../store/testimony'

export const getTestimonyInfiniteScroll = async (nextPageToken, isLoading, setState) => {
  if (isLoading) {
    return
  }
  setState({ isLoading: true })
  const result = await getFromBucketPaginate(
    PathStorageTestimony,
    nextPageToken
  )
  setState({
    data: result.urls.map(formatImageResultUrl),
    nextPageToken: result.nextPageToken,
    isLoading: false,
    totalData: result.totalData
  })
}

export const getTestimonyImageHome = async () => {
  setStateImageTestimonyHome({ isLoading: true })
  const result = await getFromBucketPaginate(PathStorageTestimony, undefined, 5)
  setStateImageTestimonyHome({
    data: result.urls.map(formatImageResultUrl),
    isLoading: false,
    totalData: result.totalData
  })
}

export const postTestimony = ({
  file,
  setUploadProgress,
  setErrorProgress,
  refetch
}) => {
  return uploadWithProgress(
    file,
    PathStorageTestimony,
    setUploadProgress,
    setErrorProgress,
    refetch
  )
}

export const deleteTestimony = async (url) => {
  await deleteFileFromBucket(url)
  setStateImageTestimonyInfinite(null, true)
}

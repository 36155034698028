import { Divider, Typography } from '@mui/material'

const Copyright = () => (
  <>
    <Divider sx={{ borderColor: 'white', marginY: 4 }} />
    <Typography variant='body1'>
      {'Copyright © '}
      PT DIAN MAKKAH MADINAH {new Date().getFullYear()}
      {'.'}
    </Typography>
  </>
)

export default Copyright

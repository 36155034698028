import { Outlet, createRoute } from '@tanstack/react-router'
import { rootRoute } from '../Root'
import { LoginCmsPage } from '../../pages'

export const authCmsRoute = createRoute({
  getParentRoute: () => rootRoute,
  component: () => (
    <>
      <Outlet />
    </>
  ),
  id: '_auth'
})

export const authLoginCmsRoute = createRoute({
  getParentRoute: () => authCmsRoute,
  path: '/auth/login',
  component: LoginCmsPage
})

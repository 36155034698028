import { useEffect } from 'react'
import {
  Box,
  Divider,
  Paper,
  Rating,
  styled,
  Typography,
  useTheme
} from '@mui/material'
import { useStore } from '@tanstack/react-store'
import { useNavigate } from '@tanstack/react-router'
import {
  setStateStorePackageSidebar,
  storePackageSidebar
} from '../../stores/store/package'
import { getPackagePagination } from '../../stores/query/package'
import dayjs from 'dayjs'

const PaperBox = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  border: 'solid',
  borderWidth: '1px',
  borderColor: theme.palette.grey['300'],
  padding: theme.spacing(2.5)
}))

const PackageWidgetSidebar = ({ title = 'Paket Lainnya' }) => {
  const theme = useTheme()
  const navigate = useNavigate()
  const { docs, isActive, perPage, count } = useStore(storePackageSidebar)

  useEffect(() => {
    if (!docs.length && count !== 0) {
      getPackagePagination({ perPage, isActive }, setStateStorePackageSidebar)
    }
  }, [docs, isActive, perPage, count])

  return (
    <PaperBox>
      <Box display='flex' flexDirection='column' gap={2}>
        <Typography
          variant='h6'
          fontWeight='fontWeightBold'
          sx={{
            position: 'relative',
            pl: 2,
            '&::after': {
              content: '""',
              position: 'absolute',
              left: 0,
              top: 4,
              bottom: 4,
              width: 3,
              backgroundColor: 'primary.main'
            }
          }}>
          {title}
        </Typography>

        <Divider />

        {docs.map((doc) => (
          <Box
            display='flex'
            gap={2}
            key={doc.id}
            onClick={() =>
              navigate({
                to: '/package/$packageId',
                params: { packageId: doc.id },
                state: { data: doc },
                resetScroll: true,
                replace: true
              })
            }>
            <Box textAlign='center'>
              <Box
                display='block'
                component='img'
                src={doc.fileUrl}
                width='90px'
                height='auto'
                borderRadius='5%'
                sx={{ cursor: 'pointer' }}
              />
              <Typography variant='caption'>
                {dayjs.unix(doc.scheduleTime).format('DD MMM YYYY')}
              </Typography>
            </Box>
            <Box overflow='hidden'>
              <Rating size='small' value={Number(doc.hotelStar)} readOnly />
              <Typography
                textOverflow='ellipsis'
                overflow='hidden'
                fontWeight='fontWeightBold'
                sx={{
                  cursor: 'pointer',
                  ':hover': { color: theme.palette.primary.main }
                }}
                noWrap>
                {doc.name}
              </Typography>
              <Typography variant='body2'>Mulai Dari</Typography>
              <Typography color='primary' fontWeight='fontWeightBold'>
                Rp. {doc.price?.toLocaleString()}
              </Typography>
            </Box>
          </Box>
        ))}
      </Box>
    </PaperBox>
  )
}

export default PackageWidgetSidebar

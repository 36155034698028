import { useState } from 'react'
import {
  Box,
  Dialog,
  IconButton,
  ImageListItemBar,
  Tooltip
} from '@mui/material'
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap'

const DialogImagePreview = (props) => {
  const [dialogOpen, setDialogOpen] = useState(false)
  const [previewImage, setPreviewImage] = useState(null)

  const handleOpen = (obj) => {
    setDialogOpen(true)
    setPreviewImage(obj)
  }

  const handleClose = () => {
    setDialogOpen(false)
    setPreviewImage(null)
  }

  return (
    <>
      {props.children(handleOpen)}
      <Dialog open={dialogOpen} onClose={handleClose} fullWidth maxWidth={'lg'}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'black'
          }}>
          <img
            src={previewImage?.img}
            alt={previewImage?.title}
            loading='lazy'
            style={{ maxWidth: '100%', maxHeight: '80vh' }}
          />
          <ImageListItemBar
            sx={{
              background:
                'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
            }}
            position='top'
            actionIcon={
              <>
                <Tooltip title='Close'>
                  <IconButton sx={{ color: 'white' }} onClick={handleClose}>
                    <ZoomInMapIcon />
                  </IconButton>
                </Tooltip>
              </>
            }
            actionPosition='right'
          />
        </Box>
      </Dialog>
    </>
  )
}

export default DialogImagePreview

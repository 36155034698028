import About from './About'
import JumbotronAbout from './JumbotronAbout'

const AboutPage = () => {
  return (
    <>
      <JumbotronAbout />
      <About />
    </>
  )
}

export default AboutPage

import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Rating,
  styled,
  Typography,
  useTheme
} from '@mui/material'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import AccessTimeIcon from '@mui/icons-material/AccessTime'
import ApartmentIcon from '@mui/icons-material/Apartment'
// import PersonIcon from '@mui/icons-material/Person'
import LocationOnIcon from '@mui/icons-material/LocationOn'
import FlightTakeoffIcon from '@mui/icons-material/FlightTakeoff'
import dayjs from 'dayjs'
import { Link } from '@tanstack/react-router'

const CaptionBox = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: theme.spacing(1),
  marginBottom: theme.spacing(1)
}))

const CaptionBoxIcon = styled(Box)(({ theme }) => ({
  alignItems: 'center',
  display: 'flex',
  textAlign: 'justify',
  gap: theme.spacing(1)
}))

const CaptionText = styled((props) => (
  <Typography variant='body2' {...props} />
))(({ theme }) => ({
  color: theme.palette.grey[600]
}))

const PackageCard = ({ packageData }) => {
  const theme = useTheme()

  return (
    <Card
      sx={{
        backgroundColor: theme.palette.grey[200],
        transition: 'box-shadow 0.3s ease-in-out',
        '&:hover': {
          transition: 'box-shadow 0.3s ease-in-out',
          boxShadow: theme.shadows['10']
        },
        boxShadow: 'none',
        borderRadius: theme.shape.borderRadius,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        height: '100%',
        border: `solid ${theme.palette.grey[300]}`
      }}>
      <CardMedia
        component='img'
        image={packageData.fileUrl}
        alt=''
        height={250}
      />
      <CardContent>
        <Typography
          variant='h5'
          fontWeight='fontWeightBold'
          color={'primary'}
          textOverflow='ellipsis'
          overflow='hidden'
          noWrap>
          {packageData.name}
        </Typography>

        <Typography variant='h5' color='secondary' fontWeight='fontWeightBold'>
          Rp. {Number(packageData.price).toLocaleString('id')},-
        </Typography>

        <CaptionBox>
          <CaptionBoxIcon>
            <CalendarMonthIcon fontSize={'24'} />
            <CaptionText>Jadwal Keberangkatan</CaptionText>
          </CaptionBoxIcon>
          <CaptionText textAlign='end'>
            {dayjs.unix(packageData.scheduleTime).format('DD MMM YYYY')}
          </CaptionText>
        </CaptionBox>

        <CaptionBox>
          <CaptionBoxIcon>
            <AccessTimeIcon fontSize={'24'} />
            <CaptionText>Durasi Paket</CaptionText>
          </CaptionBoxIcon>
          <CaptionText>{packageData.duration} Day</CaptionText>
        </CaptionBox>

        <CaptionBox>
          <CaptionBoxIcon>
            <ApartmentIcon fontSize={'24'} />
            <CaptionText>Hotel</CaptionText>
          </CaptionBoxIcon>
          <CaptionText>
            <Rating
              size='small'
              name='read-only'
              value={Number(packageData.hotelStar)}
              readOnly
            />
          </CaptionText>
        </CaptionBox>

        {/* <CaptionBox>
          <CaptionBoxIcon>
            <PersonIcon fontSize={'24'} />
            <CaptionText>Total Seat</CaptionText>
          </CaptionBoxIcon>
          <CaptionText>20 Pax</CaptionText>
        </CaptionBox> */}

        {/* <CaptionBox>
          <CaptionBoxIcon>
            <PersonIcon fontSize={'24'} />
            <CaptionText fontWeight='fontWeightBold'>
              Available Seat
            </CaptionText>
          </CaptionBoxIcon>
          <CaptionText fontWeight='fontWeightBold'>20 Pax</CaptionText>
        </CaptionBox> */}

        <CaptionBox>
          <CaptionBoxIcon>
            <LocationOnIcon fontSize={'24'} />
            <CaptionText>Berangkat Dari</CaptionText>
          </CaptionBoxIcon>
          <CaptionText>{packageData.departure}</CaptionText>
        </CaptionBox>

        <CaptionBox>
          <CaptionBoxIcon>
            <FlightTakeoffIcon fontSize={'24'} />
            <CaptionText>Maskapai</CaptionText>
          </CaptionBoxIcon>
          <CaptionText>{packageData.airline}</CaptionText>
        </CaptionBox>
      </CardContent>
      <CardActions>
        <Link
          to='/package/$packageId'
          params={{ packageId: packageData.id }}
          state={{ data: packageData, key: packageData.id }}>
          <Button size='large'>Lihat Selengkapnya</Button>
        </Link>
      </CardActions>
    </Card>
  )
}

export default PackageCard

import { Search } from '@mui/icons-material'
import { Box, Button, InputAdornment, OutlinedInput } from '@mui/material'
import React from 'react'

const TableHeader = ({ withSearch, placeholder, listActionButtons }) => {
  // TODO : Handle Search
  const renderSearch = () => (
    <OutlinedInput
      id='search'
      placeholder={placeholder}
      size='small'
      startAdornment={
        <InputAdornment position='start'>
          <Search />
        </InputAdornment>
      }
    />
  )

  const renderListButton = () =>
    listActionButtons?.map((v) => {
      return (
        <Button
          key={v.key}
          size='small'
          onClick={v?.onClick}
          variant='contained'
          startIcon={v?.startIcon}>
          {v.text}
        </Button>
      )
    })

  return (
    <Box display='flex'>
      <Box flexGrow={1}>{withSearch && renderSearch()}</Box>
      {renderListButton()}
    </Box>
  )
}

export default TableHeader

import { useEffect, useRef } from 'react'
import { Box, Container, Typography, useTheme } from '@mui/material'
import { useStore } from '@tanstack/react-store'
import {
  storeImageGalleryPage,
  setStateImageGalleryPage
} from '../../stores/store/gallery'
import { DialogImagePreview, StandardImageList } from '../../components'
import { getGalleryInfiniteScroll } from '../../stores/query/gallery'
import { getTestimonyInfiniteScroll } from '../../stores/query/testimony'
import {
  storeImageTestimonyPage,
  setStateImageTestimonyPage
} from '../../stores/store/testimony'

const GalleryPage = () => {
  const theme = useTheme()

  const {
    data: gallery,
    isLoading: loadingGallery,
    nextPageToken: nextGallery,
    totalData: totalDataGallery
  } = useStore(storeImageGalleryPage)
  const loaderRefGallery = useRef(null)

  const {
    data: testimony,
    isLoading: loadingTestimony,
    nextPageToken: nextTestimony,
    totalData: totalDataTestimony
  } = useStore(storeImageTestimonyPage)
  const loaderRefTestimony = useRef(null)

  useEffect(() => {
    if (!gallery?.length && totalDataGallery !== 0) {
      getGalleryInfiniteScroll('', false, setStateImageGalleryPage)
    }
    if (!testimony?.length && totalDataTestimony !== 0) {
      getTestimonyInfiniteScroll('', false, setStateImageTestimonyPage)
    }
  }, [gallery, testimony, totalDataGallery, totalDataTestimony])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0]
        if (
          (target.isIntersecting && nextGallery) ||
          (!gallery?.length && totalDataGallery !== 0)
        ) {
          getGalleryInfiniteScroll(
            nextGallery,
            loadingGallery,
            setStateImageGalleryPage
          )
        }
      },
      { threshold: 1.0 }
    )

    const currentRef = loaderRefGallery.current
    if (currentRef) {
      observer.observe(currentRef)
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef)
      }
    }
  }, [gallery?.length, loadingGallery, nextGallery, totalDataGallery])

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0]
        if (
          (target.isIntersecting && nextTestimony) ||
          (!testimony?.length && totalDataTestimony !== 0)
        ) {
          getTestimonyInfiniteScroll(
            nextTestimony,
            loadingTestimony,
            setStateImageTestimonyPage
          )
        }
      },
      { threshold: 1.0 }
    )

    const currentRef = loaderRefTestimony.current
    if (currentRef) {
      observer.observe(currentRef)
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef)
      }
    }
  }, [loadingTestimony, nextTestimony, testimony?.length, totalDataTestimony])

  return (
    <Container sx={{ marginY: 12 }}>
      <Typography variant='h3' fontWeight='fontWeightBold' textAlign='center'>
        Potret Jamaah{' '}
        <Typography
          variant='h3'
          fontWeight='fontWeightBold'
          component={'span'}
          color={theme.palette.primary.main}>
          Dima Tour
        </Typography>
      </Typography>

      <Box>
        <DialogImagePreview>
          {(handleOpen) => (
            <StandardImageList
              isInfiniteScroll
              isLoading={loadingGallery}
              loaderRef={loaderRefGallery}
              itemData={gallery}
              onClick={handleOpen}
            />
          )}
        </DialogImagePreview>
      </Box>

      <Typography variant='h3' fontWeight='fontWeightBold' textAlign='center'>
        Testimoni Jamaah{' '}
        <Typography
          variant='h3'
          fontWeight='fontWeightBold'
          component={'span'}
          color={theme.palette.primary.main}>
          Dima Tour
        </Typography>
      </Typography>

      <Box>
        <DialogImagePreview>
          {(handleOpen) => (
            <StandardImageList
              isInfiniteScroll
              isLoading={loadingTestimony}
              loaderRef={loaderRefTestimony}
              itemData={testimony}
              onClick={handleOpen}
            />
          )}
        </DialogImagePreview>
      </Box>
    </Container>
  )
}

export default GalleryPage

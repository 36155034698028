import { useId, useState } from 'react'
import {
  AppBar,
  Toolbar,
  Box,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemText,
  CssBaseline,
  IconButton,
  Button,
  Drawer,
  Container
} from '@mui/material'
import MenuIcon from '@mui/icons-material/Menu'
import { Link, useNavigate } from '@tanstack/react-router'
import LogoDima from '../../assets/logo_dima.png'

const NavBar = (props) => {
  const { window } = props
  const [mobileOpen, setMobileOpen] = useState(false)
  const navigate = useNavigate()

  const navItems = [
    {
      title: 'Beranda',
      link: '/',
      key: useId()
    },
    {
      title: 'Tentang Dima Tour',
      link: '/about-us',
      key: useId()
    },
    {
      title: 'Paket Dima Tour',
      link: '/package',
      key: useId()
    },
    {
      title: 'Galleri',
      link: '/gallery',
      key: useId()
    }
  ]

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState)
  }

  const drawer = (
    <Box
      onClick={handleDrawerToggle}
      sx={{ textAlign: 'center', paddingTop: 2 }}>
      <Box component='img' src={LogoDima} sx={{ width: '80px' }} />
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item.key} disablePadding>
            <ListItemButton sx={{ textAlign: 'left' }}>
              <Link to={item.link} style={{ textDecoration: 'none' }}>
                <ListItemText style={{ color: 'black' }}>
                  {item.title}
                </ListItemText>
              </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  )

  const container =
    window !== undefined ? () => window().document.body : undefined
  // TODO: Add Scroll to top
  return (
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar component='nav' color='default'>
        <Container>
          <Toolbar disableGutters>
            <IconButton
              color='inherit'
              aria-label='open drawer'
              edge='start'
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { md: 'none' } }}>
              <MenuIcon />
            </IconButton>
            <Box
              sx={{
                display: { xs: 'flex' },
                justifyContent: { xs: 'center', md: 'left' },
                alignItems: 'center',
                width: { xs: '100%', md: 'auto' },
                flexGrow: 1
              }}>
              <Box
                component='img'
                src={LogoDima}
                sx={{ width: '80px', ':hover': { cursor: 'pointer' } }}
                py={2}
                onClick={() => navigate({ to: '/' })}
              />
            </Box>
            <Box sx={{ display: { xs: 'none', md: 'block' } }}>
              {navItems.map((item) => (
                <Link key={item.key} to={item.link}>
                  <Button sx={{ color: 'black', textTransform: 'none' }}>
                    {item.title}
                  </Button>
                </Link>
              ))}
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
      <nav>
        <Drawer
          container={container}
          variant='temporary'
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{ keepMounted: true }}
          sx={{
            display: { xs: 'block', md: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: 240
            }
          }}>
          {drawer}
        </Drawer>
      </nav>
    </Box>
  )
}

export default NavBar

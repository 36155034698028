import { Box, Container, Typography, useTheme } from '@mui/material'

const About = () => {
  const theme = useTheme()

  return (
    <Container sx={{ marginY: theme.spacing(12) }}>
      <Box mb={5}>
        <Typography variant='h3' fontWeight='fontWeightBold'>
          Tentang{' '}
          <Typography
            variant='h3'
            fontWeight='fontWeightBold'
            component={'span'}
            color={theme.palette.primary.main}>
            Dima Tour & Travel
          </Typography>
        </Typography>
      </Box>

      <Box mb={5}>
        <Typography>
          PT Dian Makkah Madinah secara resmi terdaftar di Kementerian Hukum &
          Ham, pada Tgl 28 Agustus tahun 2000.
          <br />
          Kami adalah perusahaan Travel Sunnah yang bergerak di bidang :
        </Typography>

        <ul>
          <Typography component={'li'}>
            Penyelenggara Perjalanan Ibadah Umrah (PPIU), Berizin No. 183 / 2020
            di Kemenag
          </Typography>
          <Typography component={'li'}>
            Penyelenggara Ibadah Haji Khusus (PIHK), Berizin No. 693 / 2020 di
            Kemenag
          </Typography>
          <Typography component={'li'}>
            Provider Resmi Visa Haji Furoda & Umrah
          </Typography>
          <Typography component={'li'}>
            Jasa Land Arrangement berizin di Saudi (Tim Penyelenggara Umrah &
            Haji di Saudi)
          </Typography>
          <Typography component={'li'}>
            Penjualan Tiket Pesawat Umrah & Haji
          </Typography>
          <Typography component={'li'}>
            Paket Umrah, Umrah Plus, Muslim Tour, Paket Haji Furoda dan Paket
            Haji Khusus.
          </Typography>
        </ul>

        <Typography>
          Telah berpengalaman dalam melayani Jemaah Umrah & Haji Dima Tour
          ataupun beberapa Jemaah Travel di Indonesia yang menggunakan Jasa
          Kami.
        </Typography>
      </Box>

      <Box mb={5}>
        <Typography variant='h4' fontWeight='fontWeightBold'>
          Berikut{' '}
          <Typography
            variant='h4'
            fontWeight='fontWeightBold'
            component={'span'}
            color={theme.palette.primary.main}>
            VISI{' '}
          </Typography>
          &{' '}
          <Typography
            variant='h4'
            fontWeight='fontWeightBold'
            component={'span'}
            color={theme.palette.primary.main}>
            MISI
          </Typography>
        </Typography>
      </Box>

      <Box>
        <Typography
          variant='h5'
          fontWeight='fontWeightBold'
          color={theme.palette.primary.main}>
          VISI
        </Typography>
        <Typography>
          Menjadi Biro Haji Umrah yang InsyaAllah amanah sesuai Syariat dengan
          Fasilitas serta Pelayanan yang nyaman, berkualitas dan terbaik.
        </Typography>

        <Typography
          mt={2}
          variant='h5'
          fontWeight='fontWeightBold'
          color={theme.palette.primary.main}>
          MISI
        </Typography>
        <Typography>
          Memberikan Kepastian Jadwal Keberangkatan
          <br />
          Berorientasi pada Pelayanan untuk kepuasan Jemaah
          <br />
          Memberikan Fasilitas yang terbaik demi kenyamanan Jemaah
          <br />
          Menyiapkan Pembimbing sesuai sunnah yang amanah berkompeten dan
          berpengalaman
          <br />
          Mengembangkan SDM dan tata kelola Management yang Profesional
          <br />
          Menyediakan Pemandu (Muthawif) di tanah suci yang keilmuannya
          InsyaAllah sesuai dengan Al-quran dan As-sunnah
        </Typography>
      </Box>
    </Container>
  )
}

export default About

import { Box, Grid, Link, Typography, styled, useTheme } from '@mui/material'
import MapIcon from '@mui/icons-material/Map'
import PhoneIcon from '@mui/icons-material/Phone'
import ContactMailIcon from '@mui/icons-material/ContactMail'

const FooterNav = () => {
  const theme = useTheme()

  return (
    <Grid container spacing={8}>
      <Grid xs={12} md={4} item>
        <Box>
          <SectionTitle>DIMA TOUR & TRAVEL</SectionTitle>
          <Typography align='justify'>
            Perusahaan berdiri secara resmi pada Tahun 2024 dengan nama PT. Dian
            Makkah Madinah. Kami adalah Perusahaan yang bergerak di bidang Jasa
            Umrah & Haji, Jasa Land Arrangement Saudi, Provider Visa Umrah &
            Haji Furoda, Paket Umrah Plus, Paket Haji Furoda dan Paket Haji
            Khusus. Sebuah perusahaan travel yang berkepentingan di Negara yang
            berpusat di Kingdom of Saudi Arabia. Telah Melayani kurang lebih 20
            Travel Umrah & Haji di Indonesia.
          </Typography>
        </Box>
      </Grid>
      <Grid xs={12} md={4} item>
        <Box>
          <SectionTitle>KANTOR KAMI</SectionTitle>
          <Box display='flex' textAlign='justify'>
            <MapIcon />
            <Link
              href='https://maps.app.goo.gl/3sUbvb8GxAjjfiMK9'
              underline='none'
              color='inherit'
              fontWeight='fontWeightLight'
              ml={1}>
              Jl. Swadaya 1, Sukamaju Baru No A7, Kecamatan Tapos, Kota Depok,
              Jawa Barat 16455
            </Link>
          </Box>
        </Box>
      </Grid>
      <Grid xs={12} md={4} item>
        <Box>
          <SectionTitle>KONTAK INFO</SectionTitle>
          <Box display='flex' textAlign='justify' mb={2}>
            <PhoneIcon />
            <Link
              href='tel:+6285157578726'
              underline='none'
              color='inherit'
              fontWeight='fontWeightLight'
              ml={1}>
              +62-851-5757-8726
            </Link>
          </Box>
          <Box display='flex' textAlign='justify'>
            <ContactMailIcon />
            <Link
              href='mailto:akmalojan.af@gmail.com'
              underline='none'
              color='inherit'
              fontWeight='fontWeightLight'
              ml={1}>
              akmalojan.af@gmail.com
            </Link>
          </Box>
        </Box>
        <Box marginTop={theme.spacing(5)}>
          <SectionTitle>PEMBAYARAN</SectionTitle>
          <Typography>Rekening Bank Mandiri</Typography>
          <Typography>a.n PT Dian Makkah Madinah</Typography>
          <SectionTitle>1234 5678 90</SectionTitle>

          <Typography>Rekening Bank BCA</Typography>
          <Typography>a.n PT Dian Makkah Madinah</Typography>
          <SectionTitle>1234 5678 90</SectionTitle>
        </Box>
      </Grid>
    </Grid>
  )
}

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  paddingBottom: theme.spacing(2)
}))

export default FooterNav

import { useContext } from 'react'
import { Context } from '../../context/AuthContext'
import { Navigate } from '@tanstack/react-router'

export function Protected({ children }) {
  const { user } = useContext(Context)

  if (!user) {
    return <Navigate to='/auth/login' replace />
  } else {
    return children
  }
}

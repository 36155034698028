import { Store } from '@tanstack/react-store'

/**
 * @typedef ImageTestimonyInfiniteState
 * @prop {ImageTestimony[]} data
 * @prop {boolean} isLoading
 * @prop {string | undefined} nextPageToken
 * @prop {number} totalData
 */

/**
 * @type {ImageTestimonyInfiniteState}
 */
const imageTestimonyInfiniteState = {
  data: [],
  isLoading: false,
  nextPageToken: undefined,
  totalData: 10,
}

// ============================= Testimony CMS

export const storeImageTestimonyInfinite = new Store(
  imageTestimonyInfiniteState
)

/**
 * @param {ImageTestimonyInfiniteState} newState
 */
export const setStateImageTestimonyInfinite = (newState, reset) => {
  storeImageTestimonyInfinite.setState((oldState) => {
    if (reset) return imageTestimonyInfiniteState

    let data = oldState.data
    if (newState?.data?.length) {
      data = [...oldState.data, ...newState.data].reduce((pv, cv) => {
        const found = pv.find((v) => v.title === cv.title)
        if (!found) {
          pv.push(cv)
        }
        return pv
      }, [])
    }

    const result = { ...oldState, ...newState, data }

    return result
  })
}

// ============================= Testimony Home

const imageTestimonyHomeState = {
  data: [],
  isLoading: false,
  nextPageToken: undefined,
  totalData: 10,
}

export const storeImageTestimonyHome = new Store(imageTestimonyHomeState)

export const setStateImageTestimonyHome = (newState) => {
  storeImageTestimonyHome.setState((old) => {
    return { ...old, ...newState }
  })
}

// ============================= Testimony Page

const imageTestimonyPageState = {
  data: [],
  isLoading: false,
  nextPageToken: undefined,
  totalData: 10,
}

export const storeImageTestimonyPage = new Store(imageTestimonyPageState)

export const setStateImageTestimonyPage = (newState, reset = false) => {
  storeImageTestimonyPage.setState((oldState) => {
    if (reset) return imageTestimonyPageState

    let data = oldState.data
    if (newState?.data?.length) {
      data = [...oldState.data, ...newState.data].reduce((pv, cv) => {
        const found = pv.find((v) => v.title === cv.title)
        if (!found) {
          pv.push(cv)
        }
        return pv
      }, [])
    }

    const result = { ...oldState, ...newState, data }

    return result
  })
}
import { createRoute } from '@tanstack/react-router'
import { DashboardCmsPage, LayoutCms } from '../../pages'
import { rootRoute } from '../Root'

export const cmsRootRoute = createRoute({
  getParentRoute: () => rootRoute,
  component: LayoutCms,
  id: '_cms'
})

export const cmsIndexRoute = createRoute({
  getParentRoute: () => cmsRootRoute,
  path: '/cms',
  component: DashboardCmsPage
})

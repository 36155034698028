import { useEffect } from 'react'
import {
  Box,
  Button,
  Container,
  Grid,
  Typography,
  useTheme
} from '@mui/material'
import { Link } from '@tanstack/react-router'
import { useStore } from '@tanstack/react-store'
import { PackageCard } from '../../components'
import {
  setStateStorePackageHome,
  storePackageHome
} from '../../stores/store/package'
import { getPackagePagination } from '../../stores/query/package'

const Package = () => {
  const theme = useTheme()

  const { docs, isActive, perPage, count } = useStore(storePackageHome)

  useEffect(() => {
    if (!docs.length && count !== 0) {
      getPackagePagination({ perPage, isActive }, setStateStorePackageHome)
    }
  }, [docs, isActive, perPage, count])

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.grey[100],
        paddingY: theme.spacing(4)
      }}>
      <Container sx={{ marginY: theme.spacing(12) }}>
        <Box
          marginBottom={theme.spacing(4)}
          display='flex'
          flexDirection={{ xs: 'column', sm: 'row' }}
          alignItems='center'>
          <Box flexGrow={1}>
            <Typography fontWeight='fontWeightBold' variant='h4'>
              Paket Tersedia
            </Typography>
            <Typography variant='body1'>
              Allah tidak memanggil orang - orang yang mampu tapi Allah
              memampukan
              <br />
              orang - orang yang terpanggil untuk berkunjung ke Baitullah
            </Typography>
          </Box>
          <Box mt={{ xs: 4, sm: 0 }}>
            <Link to='/package'>
              <Button variant='contained' size='large'>
                Lihat Semua Paket
              </Button>
            </Link>
          </Box>
        </Box>

        <Grid container spacing={5}>
          {docs?.map((doc) => (
            <Grid
              item
              xs={12}
              md={4}
              key={`package_${doc.id}`}
              marginTop={theme.spacing(5)}>
              <PackageCard packageData={doc} />
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  )
}

export default Package

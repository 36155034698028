import { useState, useEffect } from 'react'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import {
  CircularProgress,
  IconButton,
  ImageListItemBar,
  Tooltip
} from '@mui/material'
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap'

export default function StandardImageList({
  onClick,
  itemData,

  // For Infinite scroll
  isInfiniteScroll,
  isLoading,
  loaderRef,

  // For action btn tiitle bar
  withTitleBar,
  onClickZoom
}) {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight
  })

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight
      })
    }

    window.addEventListener('resize', handleResize)

    return () => window.removeEventListener('resize', handleResize)
  }, [windowSize])

  return (
    itemData && (
      <ImageList
        sx={{ width: '100%', height: 400 }}
        cols={windowSize?.width >= 600 ? 5 : 2}
        rowHeight={200}>
        {itemData?.map((item) => (
          <ImageListItem key={item.img}>
            <img
              src={item.img}
              alt={item.title}
              loading='lazy'
              onClick={() => {
                if (onClick) onClick(item)
              }}
              style={{ cursor: 'pointer' }}
            />
            {withTitleBar && (
              <ImageListItemBar
                sx={{
                  background:
                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
                }}
                title={item.title}
                position='bottom'
                actionIcon={
                  <Tooltip title='Zoom'>
                    <IconButton
                      sx={{ color: 'white' }}
                      aria-label={`star ${item.title}`}
                      onClick={() => onClickZoom(item)}>
                      <ZoomOutMapIcon />
                    </IconButton>
                  </Tooltip>
                }
                actionPosition='right'
              />
            )}
          </ImageListItem>
        ))}
        {isInfiniteScroll && (
          <ImageListItem
            cols={5}
            ref={loaderRef}
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            {isLoading && <CircularProgress />}
          </ImageListItem>
        )}
      </ImageList>
    )
  )
}

import { useCallback, useEffect, useId, useMemo } from 'react'
import { IconButton } from '@mui/material'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import { useNavigate } from '@tanstack/react-router'
import { useStore } from '@tanstack/react-store'
import dayjs from 'dayjs'
import { TableWrapper } from '../../../components'
import { getPackagePagination } from '../../../stores/query/package'

const columns = [
  {
    id: 'name',
    label: 'Name',
    align: 'left'
  },
  {
    id: 'scheduleTime',
    label: 'Schedule',
    align: 'left'
  },
  {
    id: 'duration',
    label: 'Duration',
    align: 'left'
  },
  {
    id: 'departure',
    label: 'Departure',
    align: 'left'
  },
  {
    id: 'actionDetail',
    label: 'Action',
    align: 'center'
  }
]

const TablePackage = ({ storeParams, setState }) => {
  const navigate = useNavigate()

  const {
    docs: packages,
    count,
    startAfterDoc: next,
    endAfterDoc: prev,
    page,
    perPage,
    isLoading,
    isActive
  } = useStore(storeParams)

  const fetchPackages = useCallback(getPackagePagination, [])

  useEffect(() => {
    if (!packages.length && count !== 0) {
      fetchPackages({ perPage, isActive }, setState)
    }
  }, [fetchPackages, packages.length, perPage, isActive, setState, count])

  const onPageChange = (_, newPage) => {
    const direction = newPage > page ? 'next' : 'prev'
    setState({ page: newPage })
    fetchPackages({ perPage, next, prev, direction, isActive }, setState)
  }

  const onRowsPerPageChange = (e) => {
    const value = e.target.value
    setState({
      perPage: value,
      startAfterDoc: null,
      endAfterDoc: null
    })
    fetchPackages({ perPage: value, isActive }, setState)
  }

  const rows = useMemo(
    () =>
      packages?.map((d) => ({
        key: d.id,
        name: d.name || 'No Name',
        scheduleTime: dayjs.unix(d.scheduleTime).format('YYYY-MM-DD'),
        duration: `${d.duration} Days`,
        departure: d.departure,
        actionDetail: (
          <IconButton
            onClick={() =>
              navigate({
                to: '/cms/package/$packageId',
                params: { packageId: d.id },
                state: { data: d }
              })
            }
            title='Go To Detail'>
            <ArrowCircleRightIcon />
          </IconButton>
        )
      })),
    [packages, navigate]
  )
  return (
    <>
      <TableWrapper
        columns={columns}
        page={page}
        perPage={perPage}
        rows={rows}
        count={count}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
        isLoading={isLoading}
        // Header Props
        withSearch
        placeholder='search packages'
        listActionButtons={[
          {
            key: useId(),
            text: 'Add Packages',
            onClick: () =>
              navigate({
                to: '/cms/package/$packageId',
                params: { packageId: 'new' }
              }),
            startIcon: <AddCircleIcon />
          }
        ]}
      />
    </>
  )
}

export default TablePackage

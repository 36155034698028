import {
  endBefore,
  limit,
  limitToLast,
  orderBy,
  startAfter,
  where
} from 'firebase/firestore'
import { countDocs, getAllDocs, insertDoc, updateDoc, getOneDocById } from '.'

const PathFirestorePackages = 'packages'

/**
 * @typedef PackageData
 * @property {Object} data
 * @property {string} data.id
 * @property {string} data.name
 * @property {number} data.scheduleTime
 * @property {number} data.duration
 * @property {number} data.hotelStar
 * @property {string} data.departure
 * @property {string} data.airline
 * @property {string} data.fileUrl
 * @property {string} data.description
 * @property {boolean} data.isActive
 * @property {number} data.price
 */

/**
 *
 * @param {PackageData} data
 */
async function insertOrUpdatePackage(data) {
  const { id, ...restData } = data
  if (id) {
    await updateDoc(PathFirestorePackages, id, restData)
  } else {
    const doc = await insertDoc(PathFirestorePackages, {
      ...restData,
      isActive: true
    })

    return doc
  }
}

/**
 *
 */
async function getPackage() {}

/**
/**
 * Retrieves all active packages from Firestore.
 *
 * @param {Object} options - Options for the query.
 * @param {number} options.perPage - Number of documents to retrieve per page.
 * @param {any} options.next
 * @param {any} options.prev
 * @param {string} options.direction
 * @param {boolean} options.isActive
 * @returns {Promise<{ docs, startAfterDoc, endAfterDoc, count }>} A promise that resolves to an array of documents.
 */
async function getAllPackages(
  options = {
    perPage: 10,
    next: null,
    prev: null,
    direction: null,
    isActive: true
  }
) {
  const baseQuery = [
    where('isActive', '==', options.isActive),
    orderBy('createdAt', 'desc')
  ]
  let queries = [...baseQuery, limit(options.perPage)]

  if (options.direction === 'next' && options.next) {
    queries.push(startAfter(options.next))
  } else if (options.direction === 'prev' && options.prev) {
    queries = [
      ...baseQuery,
      endBefore(options.prev),
      limitToLast(options.perPage)
    ]
  }

  const { docs, startAfterDoc, endAfterDoc } = await getAllDocs(
    PathFirestorePackages,
    ...queries
  )
  const count = await countDocs(PathFirestorePackages, ...baseQuery)

  return { docs, startAfterDoc, endAfterDoc, count }
}

/**
 *
 * @param {string} id
 * @return {Promise<PackageData>} package
 */
async function getPackageById(id) {
  return getOneDocById(PathFirestorePackages, id)
}

export { insertOrUpdatePackage, getAllPackages, getPackage, getPackageById }

import React from 'react'
import { Box, CircularProgress } from '@mui/material'
import MyTable from './Table'
import TableHeader from './TableHeader'

/**
 * @typedef Columns
 * @prop {string} id
 * @prop {string} align
 * @prop {string | number} minWidth
 * @prop {string} label
 *
 * @param {object} param0
 * @param {number} param0.page
 * @param {number} param0.perPage
 * @param {Array<Columns>} param0.columns
 * @param {Array<object>} param0.rows
 * @param {number} param0.count
 * @param {function} param0.onPageChange
 * @param {function} param0.onRowsPerPageChange
 * @param {boolean} param0.isLoading
 * @param {boolean} param0.withSearch
 * @param {string} param0.placeholder
 * @param {Array<object>} param0.listActionButtons
 * @returns
 */
const TableWrapper = ({
  // Table Body Props
  columns,
  page,
  perPage,
  rows,
  count,
  onPageChange,
  onRowsPerPageChange,
  isLoading,

  // Table Header Props
  withSearch,
  placeholder,
  listActionButtons
}) => {
  return (
    <div>
      <TableHeader
        withSearch={withSearch}
        placeholder={placeholder}
        listActionButtons={listActionButtons}
      />
      {isLoading ? (
        <Box textAlign='center'>
          <CircularProgress />
        </Box>
      ) : (
        <MyTable
          columns={columns}
          page={page}
          perPage={perPage}
          rows={rows}
          count={count}
          onPageChange={onPageChange}
          onRowsPerPageChange={onRowsPerPageChange}
        />
      )}
    </div>
  )
}

export default TableWrapper

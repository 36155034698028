import { useEffect } from 'react'
import { Box, Button, Container, Typography, useTheme } from '@mui/material'
import { Link } from '@tanstack/react-router'
import { useStore } from '@tanstack/react-store'
import { DialogImagePreview, StandardImageList } from '../../components'
import { getGalleryImageHome } from '../../stores/query/gallery'
import { storeImageGalleryHome } from '../../stores/store/gallery'

const Gallery = () => {
  const theme = useTheme()

  const { data, totalData } = useStore(storeImageGalleryHome)

  useEffect(() => {
    if (!data?.length && totalData !== 0) {
      getGalleryImageHome()
    }
  }, [data?.length, totalData])

  return (
    <Container sx={{ marginY: theme.spacing(12) }}>
      <Box
        marginBottom={theme.spacing(4)}
        display='flex'
        flexDirection={{ xs: 'column', sm: 'row' }}
        alignItems='center'>
        <Box flexGrow={1}>
          <Typography fontWeight='fontWeightBold' variant='h4'>
            Beberapa Potret Jama'ah Saat Di Makkah
          </Typography>
        </Box>
        <Box mt={{ xs: 4, sm: 0 }}>
          <Link to='/gallery'>
            <Button variant='contained' size='large'>
              Lihat Semua
            </Button>
          </Link>
        </Box>
      </Box>

      <Box>
        <DialogImagePreview>
          {(handleOpen) => (
            <StandardImageList itemData={data} onClick={handleOpen} />
          )}
        </DialogImagePreview>
      </Box>
    </Container>
  )
}

export default Gallery

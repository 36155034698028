import { PathStorageContent, uploadFromFile } from '../../../firebase/storage'

export class CustomUploadAdapter {
  loader
  urls

  constructor(loader) {
    this.loader = loader
  }

  upload() {
    // Implement your own upload logic here.
    // For example, you might use the fetch API to send the file to your server.
    // return a promise that resolves with the uploaded image's metadata.
    return new Promise(async (resolve, reject) => {
      try {
        const file = await this.loader.file
        const url = await uploadFromFile(file, PathStorageContent)
        resolve({ default: url })
      } catch (error) {
        reject(error)
      }
    })
  }

  abort() {
    // Implement your own abort logic here.
  }
}

export default function CustomUploadAdapterPlugin(editor) {
  editor.plugins.get('FileRepository').createUploadAdapter = (loader) => {
    return new CustomUploadAdapter(loader)
  }
}

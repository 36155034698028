import { createRouter } from '@tanstack/react-router'
import { homeRoute, publicRoute, rootRoute } from './Root'
import { aboutRoute } from './About'
import { galleryRoute } from './Gallery'
import { packageRoute, packageDetailRoute } from './Package'
import { cmsRootRoute, cmsIndexRoute } from './cms'
import { accountSettingCmsRoute } from './cms/AccountSetting'
import { galleryCmsRoute } from './cms/Gallery'
import { packageCmsRoute, packageListCmsRoute } from './cms/Package'
import { testimonyCmsRoute } from './cms/Testimony'
import { authCmsRoute, authLoginCmsRoute } from './cms/Auth'

const routeTree = rootRoute.addChildren([
  publicRoute.addChildren([
    homeRoute,
    aboutRoute,
    galleryRoute,
    packageRoute,
    packageDetailRoute
  ]),
  cmsRootRoute.addChildren([
    cmsIndexRoute,
    accountSettingCmsRoute,
    galleryCmsRoute,
    packageListCmsRoute,
    packageCmsRoute,
    testimonyCmsRoute
  ]),
  authCmsRoute.addChildren([authLoginCmsRoute])
])

const router = createRouter({ routeTree })

export { router }

import { createTheme } from '@mui/material'

export const theme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: '#038B85'
    },
    secondary: {
      main: '#F5A009'
    }
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        colorDefault: '#FFF'
      }
    },
    MuiButton: {
      styleOverrides: {
        containedPrimary: {
          color: '#fff'
        }
      }
    }
  },
  typography: {
    fontFamily: `'Poppins', sans-serif`,
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightBold: 600
  }
})

import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow
} from '@mui/material'

/**
 * @typedef Columns
 * @prop {string} id
 * @prop {string} align
 * @prop {string | number} minWidth
 * @prop {string} label
 *
 * @param {object} param0
 * @param {number} param0.page
 * @param {number} param0.perPage
 * @param {Array<Columns>} param0.columns
 * @param {Array<object>} param0.rows
 * @param {number} param0.count
 * @param {function} param0.onPageChange
 * @param {function} param0.onRowsPerPageChange
 * @returns
 */
const MyTable = ({
  columns,
  page,
  perPage,
  rows,
  count,
  onPageChange,
  onRowsPerPageChange
}) => {
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', marginY: 3 }}>
      <TableContainer sx={{ maxHeight: 440 }}>
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              {columns?.map((column) => {
                return (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ minWidth: column.minWidth }}>
                    {column.label}
                  </TableCell>
                )
              })}
            </TableRow>
          </TableHead>

          <TableBody>
            {rows?.map((row) => {
              return (
                <TableRow hover role='checkbox' tabIndex={-1} key={row.key}>
                  {columns?.map((column, idx) => {
                    const value = row[column.id]
                    return (
                      <TableCell key={`${row.key}_${idx}`} align={column.align}>
                        {value}
                      </TableCell>
                    )
                  })}
                </TableRow>
              )
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <TablePagination
        rowsPerPageOptions={[5, 10, 25, 50, 100]}
        component='div'
        count={count || 0}
        rowsPerPage={perPage}
        page={page}
        onPageChange={onPageChange}
        onRowsPerPageChange={onRowsPerPageChange}
      />
    </Paper>
  )
}

export default MyTable

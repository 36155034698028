import { Paper, Typography } from '@mui/material'

const CmsContainer = ({ children, title }) => {
  return (
    <Paper variant='outlined' sx={{ padding: 2 }}>
      <Typography variant='h4' fontWeight='fontWeightBold' mb={2}>{title}</Typography>
      {children}
    </Paper>
  )
}

export default CmsContainer

import dayjs from 'dayjs'
import {
  getDownloadURL,
  getStorage,
  listAll,
  list,
  ref,
  uploadBytes,
  uploadBytesResumable,
  deleteObject
} from 'firebase/storage'

export const PathStoragePackages = '/packages'
export const PathStorageGallery = '/gallery'
export const PathStorageTestimony = '/testimony'
export const PathStorageContent = '/content'

/**
 *
 * @param {File} file
 * @param {string} pathChild
 */
export async function uploadFromFile(file, pathChild) {
  const storage = getStorage()
  const storageRef = ref(storage, `${pathChild}/${dayjs().unix()}_${file.name}`)
  const result = await uploadBytes(storageRef, file)
  const url = await getDownloadURL(result.ref)

  return url
}

export async function getAllFromBucket(pathChild) {
  const storage = getStorage()
  const storageRef = ref(storage, pathChild)
  const data = await listAll(storageRef)
  const urls = await Promise.all(data.items.map((item) => getDownloadURL(item)))

  return urls
}

/**
 *
 * @param {string} pathChild
 * @param {string | undefined} nextPageToken
 */
export async function getFromBucketPaginate(
  pathChild,
  nextPageToken,
  maxResults = 10
) {
  // Create a reference under which you want to list
  const storage = getStorage()
  const listRef = ref(storage, pathChild)
  let bucketPage
  if (nextPageToken) {
    bucketPage = await list(listRef, {
      maxResults,
      pageToken: nextPageToken
    })
  } else {
    bucketPage = await list(listRef, { maxResults })
  }

  const listAllRef = ref(storage, pathChild)
  const listAllResult = await listAll(listAllRef)

  const urls = await Promise.all(bucketPage.items.map(getDownloadURL))

  return {
    urls,
    nextPageToken: bucketPage.nextPageToken,
    totalData: listAllResult.items.length
  }
}

/**
 * Uploads a file to Firebase Storage with progress tracking.
 *
 * @param {File} file - The file to be uploaded.
 * @param {string} pathChild - The path within the storage bucket where the file will be uploaded.
 * @param {Function} setUploadProgress - A function to set the upload progress.
 * @param {Function} setErrorProgress - A function to set the error message in case of an upload error.
 * @param {Function} setDownloadUrl - A function to set the download URL of the uploaded file.
 * @param {Function} refetch - A function to refetch list image.
 */
export async function uploadWithProgress(
  file,
  pathChild,
  setUploadProgress,
  setErrorProgress,
  refetch = () => {}
) {
  const storage = getStorage()
  const storageRef = ref(storage, `${pathChild}/${dayjs().unix()}_${file.name}`)
  const uploadTask = uploadBytesResumable(storageRef, file)

  uploadTask.on(
    'state_changed',
    (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100
      setUploadProgress(progress)
    },
    (error) => {
      setErrorProgress(error)
    },
    () => {
      refetch()
    }
  )
}

export async function deleteFileFromBucket(url) {
  const storage = getStorage()
  const desertRef = ref(storage, url)
  return deleteObject(desertRef)
}

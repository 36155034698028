import { Outlet } from '@tanstack/react-router'
import { Footer, NavBar } from '../components'
import { Toolbar } from '@mui/material'

const Layout = () => {
  return (
    <>
      <NavBar />
      <Toolbar />
      <Outlet />
      <Footer />
    </>
  )
}

export default Layout

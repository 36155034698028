import Gallery from './Gallery'
import Jumbotron from './Jumbotron'
import Package from './Package'
import Testimoni from './Testimoni'
import WhyUs from './WhyUs'

const HomePage = () => {
  return (
    <>
      <Jumbotron />
      <WhyUs />
      <Gallery />
      <Package />
      <Testimoni />
    </>
  )
}

export default HomePage

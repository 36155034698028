import {
  deleteFileFromBucket,
  getFromBucketPaginate,
  PathStorageGallery,
  uploadWithProgress
} from '../../firebase/storage'
import { formatImageResultUrl } from '../../libs/helper'
import {
  setStateImageGalleryInfinite,
  setStateImageGalleryHome
} from '../store/gallery'

export const getGalleryInfiniteScroll = async (
  nextPageToken,
  isLoading,
  setState
) => {
  if (isLoading) {
    return
  }
  setState({ isLoading: true })
  const result = await getFromBucketPaginate(PathStorageGallery, nextPageToken)
  setState({
    data: result.urls.map(formatImageResultUrl),
    nextPageToken: result.nextPageToken,
    isLoading: false,
    totalData: result.totalData
  })
}

export const getGalleryImageHome = async () => {
  setStateImageGalleryHome({ isLoading: true })
  const result = await getFromBucketPaginate(PathStorageGallery, undefined, 5)
  setStateImageGalleryHome({
    data: result.urls.map(formatImageResultUrl),
    isLoading: false,
    totalData: result.totalData
  })
}

export const postGallery = ({
  file,
  setUploadProgress,
  setErrorProgress,
  refetch
}) => {
  return uploadWithProgress(
    file,
    PathStorageGallery,
    setUploadProgress,
    setErrorProgress,
    refetch
  )
}

export const deleteGallery = async (url) => {
  await deleteFileFromBucket(url)
  setStateImageGalleryInfinite(null, true)
}

import { Box, Divider, Paper, styled, Typography } from '@mui/material'
import BusinessIcon from '@mui/icons-material/Business'
import LocalPhoneIcon from '@mui/icons-material/LocalPhone'
import EmailIcon from '@mui/icons-material/Email'

const BoxIcon = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '5%',
  height: 40,
  width: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  mr: 2
}))

const PaperBox = styled(Paper)(({ theme }) => ({
  boxShadow: 'none',
  border: 'solid',
  borderWidth: '1px',
  borderColor: theme.palette.grey['300'],
  padding: theme.spacing(2.5),
}))

const CompanyCard = () => {
  return (
    <PaperBox>
      <Box display='flex' flexDirection='column' gap={2}>
        <Typography
          variant='h6'
          fontWeight='fontWeightBold'
          sx={{
            position: 'relative',
            pl: 2,
            '&::after': {
              content: '""',
              position: 'absolute',
              left: 0,
              top: 4,
              bottom: 4,
              width: 3,
              backgroundColor: 'primary.main'
            }
          }}>
          Informasi Travel
        </Typography>

        <Divider />

        <Box display='flex' alignItems='center' gap={2}>
          <BoxIcon>
            <BusinessIcon sx={{ color: 'white' }} />
          </BoxIcon>
          <Box>
            <Typography>Nama Perusahaan</Typography>
            <Typography fontWeight='fontWeightBold'>
              PT. DIAN MAKKAH MADINAH
            </Typography>
          </Box>
        </Box>

        <Box display='flex' alignItems='center' gap={2}>
          <BoxIcon>
            <LocalPhoneIcon sx={{ color: 'white' }} />
          </BoxIcon>
          <Box>
            <Typography>Nomor Telepon</Typography>
            <Typography fontWeight='fontWeightBold'>085157578726</Typography>
          </Box>
        </Box>

        <Box display='flex' alignItems='center' gap={2}>
          <BoxIcon>
            <EmailIcon sx={{ color: 'white' }} />
          </BoxIcon>
          <Box>
            <Typography>Alamat Email</Typography>
            <Typography fontWeight='fontWeightBold'>
              admin@dimatour.id
            </Typography>
          </Box>
        </Box>
      </Box>
    </PaperBox>
  )
}

export default CompanyCard

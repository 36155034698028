import { Outlet } from '@tanstack/react-router'
import { SideBar } from '../../components'
import { Protected } from './Protected'

const LayoutCms = () => {
  return (
    <Protected>
      <SideBar>
        <Outlet />
      </SideBar>
    </Protected>
  )
}

export default LayoutCms

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
  apiKey: 'AIzaSyC62MeoGegbNzli2KxVl0uqgpwMd00vWSs',
  authDomain: 'dima-tour-travel.firebaseapp.com',
  projectId: 'dima-tour-travel',
  storageBucket: 'dima-tour-travel.appspot.com',
  messagingSenderId: '943935302121',
  appId: '1:943935302121:web:ca29ba58affbe84cc74378',
  measurementId: 'G-442XHHHDM2'
}

import { ThemeProvider } from '@emotion/react'
import './App.css'
import { QueryClientProvider } from '@tanstack/react-query'
import { RouterProvider } from '@tanstack/react-router'
import { theme } from './Theme'
import { queryClient } from './QueryClient'
import { router } from './routes'
import { AuthContext } from './context/AuthContext'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'

function App() {
  return (
    <ThemeProvider theme={theme}>
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <AuthContext>
            <div className='App'>
              <RouterProvider router={router} />
            </div>
          </AuthContext>
        </LocalizationProvider>
      </QueryClientProvider>
    </ThemeProvider>
  )
}

export default App

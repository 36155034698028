import { useEffect, useState } from 'react'
import { useForm } from '@tanstack/react-form'
import { useParams, useLocation, useNavigate } from '@tanstack/react-router'
import { DatePicker } from '@mui/x-date-pickers'
import dayjs from 'dayjs'
import LoadingButton from '@mui/lab/LoadingButton'
import SaveIcon from '@mui/icons-material/Save'
import VisibilityOnIcon from '@mui/icons-material/Visibility'
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff'
import {
  Box,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  InputAdornment,
  InputLabel,
  Rating,
  TextField,
  Typography,
  styled
} from '@mui/material'
import { useStore } from '@tanstack/react-store'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import {
  ClassicEditor,
  Bold,
  Essentials,
  Heading,
  Indent,
  IndentBlock,
  Italic,
  Link,
  List,
  MediaEmbed,
  Paragraph,
  Table,
  Undo,
  Image as CKImage,
  ImageInsert
} from 'ckeditor5'
import 'ckeditor5/ckeditor5.css'
import { getImagePackage, postPackage } from '../../../stores/query/package'
import {
  CmsContainer,
  InputFileUpload,
  StandardImageList
} from '../../../components'
import { storeImagePackageList } from '../../../stores/store/package'
import CustomUploadAdapterPlugin from './CustomUploadAdapter'

const Image = styled('img')({
  height: 'auto',
  width: '300px'
})

const PackageCmsPage = () => {
  const { packageId } = useParams({ strict: false })
  const { state: stateData } = useLocation()
  const [previewImage, setPreviewImage] = useState('')
  const navigate = useNavigate()
  const {
    data: imagesData,
    isLoading,
    totalData
  } = useStore(storeImagePackageList)

  useEffect(() => {
    if (!imagesData?.length && totalData !== 0) {
      getImagePackage()
    }
  }, [imagesData?.length, totalData])

  const {
    Field,
    handleSubmit,
    state: { isSubmitting }
  } = useForm({
    defaultValues: {
      id: stateData?.data?.id || '',
      name: stateData?.data?.name || '',
      scheduleTime: stateData?.data?.scheduleTime || dayjs().unix(),
      duration: stateData?.data?.duration || 0,
      hotelStar: stateData?.data?.hotelStar || 0,
      departure: stateData?.data?.departure || '',
      airline: stateData?.data?.airline || '',
      fileUrl: stateData?.data?.fileUrl || '',
      description: stateData?.data?.description || '',
      price: stateData?.data?.price || 0
    },
    onSubmit: async ({ value }) => postPackage(value)
  })

  const handleSaveOrCreate = async (e) => {
    e.preventDefault()
    await handleSubmit()
    navigate({ to: '/cms/package' })
  }

  const handleOnClickSelectImage = (item, handleChange) => {
    setPreviewImage(item?.img)
    handleChange(item?.img)
  }

  const handleFileUpload = (e, handlechange) => {
    const file = e.target.files[0]
    const tempUrl = URL.createObjectURL(file)
    setPreviewImage(tempUrl)
    handlechange(file)
  }

  const handleDeactivatePackage = async (value) => {
    await postPackage(value)
    navigate({ to: '/cms/package' })
  }

  const isCreateNew = packageId === 'new'
  const title = isCreateNew ? 'Create New Package' : 'Package Detail'

  if (!previewImage && stateData?.data?.fileUrl) {
    setPreviewImage(stateData?.data?.fileUrl)
  }

  return (
    <CmsContainer title={title}>
      <Box component='form' onSubmit={handleSaveOrCreate}>
        <Grid container spacing={5} my={2}>
          <Grid item xs={12} md={6}>
            <Field
              name='name'
              children={({ state, handleChange, handleBlur }) => (
                <TextField
                  defaultValue={state.value}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label='Package Name'
                  placeholder='Umroh Januari 2025'
                  type='text'
                  required
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name='price'
              children={({ state, handleChange, handleBlur }) => (
                <TextField
                  value={state.value}
                  onChange={(e) => {
                    let value = e.target.value.replace(/\D/g, '')
                    if (value.length > 1 && Number(value[0]) === 0) {
                      value = value.slice(1)
                    }
                    handleChange(Number(value))
                  }}
                  onBlur={handleBlur}
                  label='Package Price'
                  placeholder='32000000'
                  type='text'
                  required
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>Rp</InputAdornment>
                    )
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name='scheduleTime'
              children={({ state, handleChange }) => (
                <DatePicker
                  label='Schedule Time'
                  value={dayjs.unix(state.value)}
                  onChange={(newValue) =>
                    handleChange(newValue?.startOf('day')?.unix())
                  }
                  sx={{ width: '100%' }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name='duration'
              children={({ state, handleChange, handleBlur }) => (
                <TextField
                  defaultValue={state.value}
                  onChange={(e) => handleChange(Number(e.target.value))}
                  onBlur={handleBlur}
                  label='Duration In Day'
                  placeholder='Duration In Day'
                  type='number'
                  required
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name='hotelStar'
              children={({ state, handleChange }) => (
                <>
                  <InputLabel htmlFor='htmlStar'>Hotel Star</InputLabel>
                  <Rating
                    size='medium'
                    name='hotelStar'
                    value={Number(state.value)}
                    onChange={(e) => handleChange(Number(e.target.value))}
                  />
                </>
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name='departure'
              children={({ state, handleChange, handleBlur }) => (
                <TextField
                  defaultValue={state.value}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label='Departure'
                  placeholder='Jakarta'
                  type='text'
                  required
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <Field
              name='airline'
              children={({ state, handleChange, handleBlur }) => (
                <TextField
                  defaultValue={state.value}
                  onChange={(e) => handleChange(e.target.value)}
                  onBlur={handleBlur}
                  label='Airline'
                  placeholder='Garuda / Batik Air'
                  type='text'
                  required
                  fullWidth
                />
              )}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography>Description</Typography>
            <Field
              name='description'
              children={({ state, handleChange, handleBlur }) => (
                <CKEditor
                  editor={ClassicEditor}
                  config={{
                    toolbar: [
                      'undo',
                      'redo',
                      '|',
                      'heading',
                      '|',
                      'bold',
                      'italic',
                      '|',
                      'link',
                      'insertTable',
                      'insertImage',
                      'mediaEmbed',
                      '|',
                      'bulletedList',
                      'numberedList',
                      'indent',
                      'outdent'
                    ],
                    plugins: [
                      Bold,
                      Essentials,
                      Heading,
                      Indent,
                      IndentBlock,
                      Italic,
                      Link,
                      List,
                      MediaEmbed,
                      Paragraph,
                      Table,
                      Undo,
                      CKImage,
                      ImageInsert
                    ],
                    initialData: '',
                    extraPlugins: [CustomUploadAdapterPlugin]
                  }}
                  data={state.value}
                  onBlur={handleBlur}
                  onChange={(_, editor) => {
                    const value = editor.getData()
                    handleChange(value)
                  }}
                  onReady={(editor) => {
                    // You can store the "editor" and use when it is needed.
                    // console.log("Editor is ready to use!", editor);
                    editor.editing.view.change((writer) => {
                      writer.setStyle(
                        'height',
                        '400px',
                        editor.editing.view.document.getRoot()
                      )
                    })
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Field
              name='fileUrl'
              children={({ handleChange }) => (
                <Box
                  textAlign='center'
                  display='flex'
                  flexDirection='column'
                  justifyContent='center'
                  alignItems='center'
                  gap={2}>
                  <Typography textAlign='center'>Preview Image</Typography>
                  {/* Preview Image */}
                  {previewImage && <Image src={previewImage} alt='' />}

                  <InputFileUpload
                    accept='image/*'
                    onChange={(e) => handleFileUpload(e, handleChange)}
                  />

                  <Divider sx={{ my: 2, width: '100%' }}>
                    <Chip label='OR SELECT FILE' size='small' />
                  </Divider>

                  {isLoading ? (
                    <Box textAlign='center'>
                      <CircularProgress />
                    </Box>
                  ) : (
                    <StandardImageList
                      itemData={imagesData}
                      onClick={(item) =>
                        handleOnClickSelectImage(item, handleChange)
                      }
                    />
                  )}
                </Box>
              )}
            />
          </Grid>
          <Grid item xs={12} textAlign='center'>
            <LoadingButton
              type='submit'
              onSubmit={handleSaveOrCreate}
              sx={{ minWidth: '150px' }}
              loading={isSubmitting}
              loadingPosition='start'
              startIcon={<SaveIcon />}
              variant='contained'>
              <span>{isSubmitting ? 'Saving Data...' : 'Save'}</span>
            </LoadingButton>

            {!isCreateNew && (
              <LoadingButton
                type='button'
                onClick={() =>
                  handleDeactivatePackage({
                    ...stateData?.data,
                    isActive: !stateData?.data?.isActive
                  })
                }
                sx={{ minWidth: '150px', ml: 2 }}
                loading={false}
                loadingPosition='start'
                startIcon={
                  stateData?.data?.isActive ? (
                    <VisibilityOffIcon />
                  ) : (
                    <VisibilityOnIcon />
                  )
                }
                variant='contained'
                color={stateData?.data?.isActive ? 'error' : 'primary'}>
                {stateData?.data?.isActive ? 'Deactivate' : 'Activate'}
              </LoadingButton>
            )}
          </Grid>
        </Grid>
      </Box>
    </CmsContainer>
  )
}

export default PackageCmsPage

import { Box, Container, Typography } from '@mui/material'
import AboutBgImage from '../../assets/about-bg.jpg'

const JumbotronAbout = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        minHeight: '40vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: 'white',
        overflow: 'hidden',
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: `url(${AboutBgImage})`,
          backgroundSize: 'cover',
          backgroundPosition: 'bottom',
          zIndex: -2
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          zIndex: -1
        }
      }}>
      <Container>
        <Typography
          variant='h4'
          component='h1'
          fontWeight='fontWeightBold'
          gutterBottom>
          Tentang Kami
        </Typography>
        <Typography
          variant='h5'
          component='p'
          paragraph
          fontWeight='fontWeightMedium'>
          Umrah & Haji Mudah Sesuai Syariah
        </Typography>
      </Container>
    </Box>
  )
}

export default JumbotronAbout

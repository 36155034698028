import { Container, styled } from '@mui/material'
import Copyright from './Copyright'
import FooterNav from './FooterNav'

const Footer = () => {
  return (
    <FooterEl>
      <Container>
        <FooterNav />
        <Copyright />
      </Container>
    </FooterEl>
  )
}

const FooterEl = styled('footer')(({ theme }) => ({
  padding: `${theme.spacing(5)} 0`,
  color: 'white',
  marginTop: 'auto',
  backgroundColor: theme.palette.primary.main
}))

export default Footer

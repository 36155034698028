import { useEffect, useRef, useState } from 'react'
import {
  Backdrop,
  Box,
  CircularProgress,
  Dialog,
  IconButton,
  ImageListItemBar,
  Tooltip
} from '@mui/material'
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap'
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'
import StandardImageList from '../ImageList'
import ConfirmationDialog from '../ConfirmationDialog'

const CmsImageList = ({
  data,
  isLoading,
  nextPageToken,
  fetchImage,
  deleteImage,
  totalData
}) => {
  const [previewOpen, setPreviewOpen] = useState(false)
  const [previewObj, setPreviewObj] = useState(null)
  const [backdropOpen, setBackdropOpen] = useState(false)
  const loaderRef = useRef(null)

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const target = entries[0]
        if (
          (target.isIntersecting && nextPageToken) ||
          (!data?.length && totalData !== 0)
        ) {
          fetchImage(nextPageToken, isLoading)
        }
      },
      { threshold: 1.0 }
    )

    const currentRef = loaderRef.current
    if (currentRef) {
      observer.observe(currentRef)
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef)
      }
    }
  }, [data, fetchImage, isLoading, nextPageToken, totalData])

  const handleClickImage = (item) => {
    setPreviewOpen(true)
    setPreviewObj(item)
  }

  const handleCloseImage = () => {
    setPreviewOpen(false)
    setPreviewObj(null)
  }

  const handleDeleteImage = async () => {
    setBackdropOpen(true)
    await deleteImage(previewObj.reference)
    setTimeout(async () => {
      await fetchImage('', false)
      setBackdropOpen(false)
      setPreviewOpen(false)
    }, 1000)
  }

  return (
    <>
      <StandardImageList
        isInfiniteScroll
        isLoading={isLoading}
        loaderRef={loaderRef}
        itemData={data}
        onClick={handleClickImage}
        withTitleBar
        onClickZoom={handleClickImage}
      />

      <Dialog
        open={previewOpen}
        onClose={handleCloseImage}
        fullWidth
        maxWidth={'lg'}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'black'
          }}>
          <img
            src={previewObj?.img}
            alt={previewObj?.title}
            loading='lazy'
            style={{ maxWidth: '100%', maxHeight: '80vh' }}
          />
          <ImageListItemBar
            sx={{
              background:
                'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)'
            }}
            title={previewObj?.title}
            position='top'
            actionIcon={
              <>
                <Tooltip title='Close'>
                  <IconButton
                    sx={{ color: 'white' }}
                    onClick={handleCloseImage}>
                    <ZoomInMapIcon />
                  </IconButton>
                </Tooltip>
                <ConfirmationDialog
                  title='Warning'
                  textConfirm='Delete'
                  textCancel='Cancel'
                  description='Are you sure you want to proceed?'
                  colorConfirm='error'
                  response={handleDeleteImage}>
                  {(showDialog) => (
                    <Tooltip title='Delete Image'>
                      <IconButton sx={{ color: 'white' }} onClick={showDialog}>
                        <DeleteForeverIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </ConfirmationDialog>
              </>
            }
            actionPosition='right'
          />
        </Box>
      </Dialog>

      <Backdrop
        sx={(theme) => ({ color: '#fff', zIndex: theme.zIndex.modal + 1 })}
        open={backdropOpen}>
        <CircularProgress />
      </Backdrop>
    </>
  )
}

export default CmsImageList
